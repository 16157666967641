import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/flomesh.io/flomesh.io/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "get-apiv1repo"
    }}>{`GET `}<inlineCode parentName="h3">{`/api/v1/repo`}</inlineCode></h3>
    <p>{`Retrieve codebase list`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl http://localhost:6060/api/v1/repo
/tutorial/01-hello
/tutorial/02-routing
/tutorial/03-connection-pool
/tutorial/04-load-balancing
/tutorial/05-logging
/tutorial/06-path-rewriting
/tutorial/07-jwt
/tutorial/08-plugins
/tutorial/09-configuration
/tutorial/10-ban
/tutorial/11-throttle
/tutorial/12-body-transform
`}</code></pre>
    <h3 {...{
      "id": "post-apiv1repo"
    }}>{`POST `}<inlineCode parentName="h3">{`/api/v1/repo`}</inlineCode></h3>
    <p>{`Create new codebase`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -X POST http://localhost:6060/api/v1/repo/hello
# Check list and you will see new codebase hello in the list
$ curl http://localhost:6060/api/v1/repo
/hello
/tutorial/01-hello
/tutorial/02-routing
/tutorial/03-connection-pool
/tutorial/04-load-balancing
/tutorial/05-logging
/tutorial/06-path-rewriting
/tutorial/07-jwt
/tutorial/08-plugins
/tutorial/09-configuration
/tutorial/10-ban
/tutorial/11-throttle
/tutorial/12-body-transform
`}</code></pre>
    <h3 {...{
      "id": "get-apiv1repocodebase"
    }}>{`GET `}<inlineCode parentName="h3">{`/api/v1/repo/[CODEBASE]`}</inlineCode></h3>
    <p>{`View codebase information: version, path, file list, and so on.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -s http://localhost:6060/api/v1/repo/hello | jq .
{
  "version": "1", #Version
  "path": "/hello", #Path
  "main": "/main.js", #Main entry script
  "files": [ #documents list
    "/main.js"
  ],
  "editFiles": [], #Modified files which hasn't yet submitted
  "erasedFiles": [],
  "baseFiles": [],
  "derived": [],
  "instances": {}
}
`}</code></pre>
    <h3 {...{
      "id": "get-apiv1repocodebasefile_name"
    }}>{`GET `}<inlineCode parentName="h3">{`/api/v1/repo/[CODEBASE]/[FILE_NAME]`}</inlineCode></h3>
    <p>{`View codebase file`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -s http://localhost:6060/api/v1/repo/hello/main.js
pipy()
`}</code></pre>
    <h3 {...{
      "id": "post-apiv1repocodebasefile_name"
    }}>{`POST `}<inlineCode parentName="h3">{`/api/v1/repo/[CODEBASE]/[FILE_NAME]`}</inlineCode></h3>
    <p>{`Update codebase file`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -X POST http://localhost:6060/api/v1/repo/hello/main.js --data """pipy()
  .listen(8080)
  .serveHTTP(
    new Message('Hi!')
  )"""
  
$ curl -s http://localhost:6060/api/v1/repo/hello/main.js
pipy()
  .listen(8080)
  .serveHTTP(
    new Message('Hi!')
  )
#View codebase modified files which are not yet submitted  
$ curl -s http://localhost:6060/api/v1/repo/hello | jq .
{
  "version": "1",
  "path": "/hello",
  "main": "/main.js",
  "files": [
    "/main.js"
  ],
  "editFiles": [
    "/main.js"
  ],
  "erasedFiles": [],
  "baseFiles": [],
  "derived": [],
  "instances": {}
}
`}</code></pre>
    <h3 {...{
      "id": "post-apiv1repocodebase"
    }}>{`POST `}<inlineCode parentName="h3">{`/api/v1/repo/[CODEBASE]`}</inlineCode></h3>
    <p>{`Update codebase. API is similar to that of updating codebase file, but it requires `}<inlineCode parentName="p">{`version`}</inlineCode>{` information and that need to be different than previous version.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -X POST http://localhost:6060/api/v1/repo/hello --data '{"version": '2'}'
`}</code></pre>
    <h3 {...{
      "id": "post-apiv1program"
    }}>{`POST `}<inlineCode parentName="h3">{`/api/v1/program`}</inlineCode></h3>
    <p>{`Run the specified Codebase with the Pipy Repo instance, or reload it if the Codebase is already running.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -X POST http://localhost:6060/api/v1/program --data '/hello'
`}</code></pre>
    <h3 {...{
      "id": "get-apiv1program"
    }}>{`GET `}<inlineCode parentName="h3">{`/api/v1/program`}</inlineCode></h3>
    <p>{`View the codebase running on the current Pipy Repo instance.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl http://localhost:6060/api/v1/program
/hello
`}</code></pre>
    <h3 {...{
      "id": "delete-apiv1program"
    }}>{`DELETE `}<inlineCode parentName="h3">{`/api/v1/program`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -X DELETE http://localhost:6060/api/v1/program
`}</code></pre>
    <h3 {...{
      "id": "get-apiv1dump-store"
    }}>{`GET `}<inlineCode parentName="h3">{`/api/v1/dump-store`}</inlineCode></h3>
    <p>{`Dump the Repo and print all codebase information in the log: list of files, contents, and so on.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl http://localhost:6060/api/v1/dump-store
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      